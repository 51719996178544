/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { TextField, Grid, MenuItem, Typography } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ColorsService from "services/ColorsService";
export const CreateColor = ({ color, isCreate, backToPrevious, direction }) => {
  const FILE_SIZE = 524288;
  const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
  // const phoneNumberRegEx = /^[0-1]{2}[0-9]{9}/;
  // const PasswordRegEx = /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
  const colorValidation = yup.object().shape({
    name: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
    code: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
  });
  const initialValue = useMemo(() => {
    if (isCreate)
      return {
        name: "",
        code: "",
      };
    else
      return {
        name: color?.name,
        code: color?.code,
      };
  }, [isCreate]);

  const handleSubmit = (values, props) => {
    var data = new FormData();
    Object.keys(values).forEach((e) => {
      data.append(e, values[e]);
    });
    if (isCreate) {
      ColorsService.addColor(data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    } else {
      ColorsService.updateColor(color.id, data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValue}
        validationSchema={colorValidation}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const { name, code } = props.values;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم" : "Name"}
                    name="name"
                    variant="outlined"
                    margin="dense"
                    value={name}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="name" />}
                    error={props.errors.name && props.touched.name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الرمز" : "Code"}
                    name="code"
                    variant="outlined"
                    margin="dense"
                    value={code}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="code" />}
                    error={props.errors.code && props.touched.code}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDButton variant="contained" type="submit" color="primary" fullWidth>
                    {direction == "rtl" ? "إضافة" : "Submit"}
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={backToPrevious}
                    variant="contained"
                    type="submit"
                    color="warning"
                    fullWidth
                  >
                    {direction == "rtl" ? "عودة" : "Back"}
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
